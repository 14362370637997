<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/download/store/distributor/index.vue
 * @Author: 张兴业
 * @Date: 2020-12-29 14:44:07
 * @LastEditors: zxy
 * @LastEditTime: 2021-12-23 16:07:02
-->

<template>
  <div class="container">
    <!-- 
      pc端
     -->
    <div v-if="!system.isPhone">
      <el-image
        :src="require('@/assets/download/bg.png')"
        fit="cover"
        class="bg"
      />
      <el-image
        v-if="type === '0'"
        :src="require('@/assets/download/app_dd.png')"
        class="app_brand"
      />
      <div class="left_top">
        <div class="logo_back">
          <el-image
            :src="require(`@/assets/download/${logoImgPath}.png`)"
            class="logo"
            style="width: 60px"
          />
          <span class="logo_title0">
            <span class="logo_title1">az</span>
            <span class="logo_title2">{{ title }}</span>
          </span>
          <span class="logo_title">{{ logoTitle }}</span>
        </div>
      </div>
      <div class="qrcode">
        <div class="qrcode_content">
          <div class="qrcode_l">
            <el-image
              :src="require(`@/assets/download/${iosDownPath}.png`)"
              class="qrcode_img"
            />
            <a :href="ios">
              <el-image
                :src="require('@/assets/download/ios.png')"
                class="app_btn"
              />
            </a>
          </div>
          <div class="qrcode_r">
            <el-image
              :src="require(`@/assets/download/${androidDownPath}.png`)"
              class="qrcode_img"
            />
            <el-image
              v-if="isWxClient && isMobileTerminal"
              :src="require('@/assets/download/android.png')"
              class="app_btn"
              @click="showGuid"
            />
            <a v-else :href="android_cdn">
              <el-image
                :src="require('@/assets/download/android.png')"
                class="app_btn"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- 
      wap端
     -->
    <div v-if="system.isPhone">
      <Wap
        :ios="ios"
        :isWxClient="isWxClient"
        :isMobileTerminal="isMobileTerminal"
        :android_cdn="android_cdn"
        :type="type"
        @showGuid="showGuid"
      />
    </div>
    <div v-if="show" class="guid_back" @click.stop="closeGuid">
      <div class="guid">
        <img src="@/assets/download/guid.png" alt="" />
      </div>
      <div class="mask_c">
        <div class="hint1">1. 点击右上角</div>
        <div class="hint2">
          {{
            !isIPhoneOrIPad
              ? "2. 点击`在浏览器中打开`"
              : "2. 点击`在Safari中打开`"
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isIPhoneOrIPad,
  isWxClient,
  isMobileTerminal,
  isAndroid,
} from "@/utils/pattern";
import Wap from "./components/Wap";

export default {
  components: {
    Wap,
  },
  data() {
    return {
      show: false,
      type: 0, // 0 精品通， 1 火龙购
      // ios: "itms-apps://itunes.apple.com/app/6670604194",
      // android:
      //   "https://a.app.qq.com/o/simple.jsp?pkgname=com.azgo.globalproducts",
      // android_cdn:
      //   "https://assets-apk.hooloogostore.com/store-distributor-new/az_android_app.apk",
      redirect: undefined,
    };
  },

  computed: {
    title() {
      return this.type === "0" ? "精品通" : "火龙购";
    },
    ios() {
      return this.type === "0"
        ? "itms-apps://itunes.apple.com/app/6670604194"
        : "itms-apps://itunes.apple.com/app/6670529754";
    },
    android_cdn() {
      return this.type === "0"
        ? "https://assets-apk.hooloogostore.com/store-distributor/jpt.apk"
        : "https://assets-apk.hooloogostore.com/store-retail/hlg.apk";
    },
    // appBrandImgPath() {
    //   return this.type === "0" ? require("@/assets/download/app_dd.png") : null;
    // },
    logoImgPath() {
      return this.type === "0" ? "logo-retail" : "retail_app";
    },
    logoTitle() {
      return this.type === "0" ? "全球精品通全球" : "甄选品质好货 分享品位生活";
    },
    iosDownPath() {
      return this.type === "0" ? "app_ios_d" : "app_ios";
    },
    androidDownPath() {
      return this.type === "0" ? "app_android_d" : "app_android";
    },
    isIPhoneOrIPad() {
      return isIPhoneOrIPad();
    },
    isWxClient() {
      return isWxClient();
    },
    isMobileTerminal() {
      return isMobileTerminal();
    },
    isAndroid() {
      return isAndroid();
    },
  },
  mounted() {
    if (this.isAndroid && this.isWxClient) {
      this.show = true;
    }
    this.type = this.$route.query.type ?? 0;
  },
  methods: {
    showGuid() {
      if (!this.isWxClient) {
        return;
      }
      this.show = true;
    },
    closeGuid() {
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100vh;

  .bg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .app_brand {
    position: absolute;
    bottom: 16%;
    right: 12%;
    width: 15%;
  }

  .left_top {
    position: absolute;
    top: 13.9%;
    left: 10.4%;
    width: 80%;
    text-align: left;
    color: #000000;
    font-family: PingFangSC-Light, PingFang SC;

    .logo_back {
      display: flex;
      flex-direction: row;

      .logo {
        width: 5%;
        height: calc(100%);
      }

      .logo_title0 {
        font-size: 28px;
        font-weight: 300;
        margin: auto 24px;

        .logo_title1 {
          color: #bf9264;
          font-weight: 700;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
        }

        .logo_title2 {
          font-weight: 700;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
        }
      }

      .logo_title {
        // margin-top: 30px;
        font-size: 28px;
        font-weight: 300;
        margin: auto 0;
      }
    }
  }

  .qrcode {
    position: absolute;
    left: 10.4%;
    bottom: 25%;
    width: 28.125%;

    .logo_subtitle {
      font-size: 24px;
      font-weight: 400;
      width: 800px;
      text-align: left;
    }

    .qrcode_content {
      margin-top: 66px;
      display: flex;
    }

    .qrcode_l,
    .qrcode_r {
      width: 40.7%;
      text-align: center;
    }

    .qrcode_r {
      margin-left: 20%;
    }

    .qrcode_img {
      width: 68.2%;
    }

    .app_btn {
      margin-top: 30px;
      width: 100%;
      display: block;
    }
  }

  .guid_back {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
  }
  .guid {
    text-align: right;
    padding: 8px 16px;
  }
  .guid img {
    width: 120px;
    height: 156px;
  }
  .mask_c {
    background: #fff;
    margin: 8px 24px auto 24px;
    border-radius: 5px;
    padding: 12px;
    font-size: 14px;
    color: #000;
    text-align: left;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100%;
    height: 100vh;

    .bg {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .app_brand {
      position: absolute;
      bottom: 4%;
      left: 50%;
      transform: translateX(-50%);
      width: 35%;
    }

    .left_top {
      position: absolute;
      top: 8%;
      left: 50%;
      transform: translateX(-50%);
      width: 92%;
      text-align: center;
      color: #000000;
      font-family: PingFangSC-Light, PingFang SC;

      .logo_back {
        display: flex;
        flex-direction: column;
        .logo {
          width: 50%;
          margin: auto;
        }

        .logo_title0 {
          margin: 20px auto;
        }

        .logo_title {
          font-size: 20px;
          font-weight: 300;
        }
      }
    }

    .qrcode {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;

      .logo_subtitle {
        font-size: 13px;
        font-weight: 400;
        width: 100%;
        text-align: center;
      }

      .qrcode_content {
        margin-top: 30px;
        display: flex;
      }

      .qrcode_l,
      .qrcode_r {
        width: 40%;
        text-align: center;
      }

      .qrcode_r {
        margin-left: 20%;
      }

      .qrcode_img {
        width: 68.2%;
      }

      .app_btn {
        margin-top: 10px;
        width: 100%;
        display: block;
      }
    }
  }
}
</style>
